import './App.css';
import Background from './components/Background';

function App() {
  return (
    <Background />
  );
}

export default App;

import React from "react";
import Logo from "../images/HI_Logo_1_white.svg";

const Background = () => {
  return (
    <div className="background">
      <div
        style={{
          width: "100vw",
          height: "15%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img src={Logo} className="logo" alt="Logo" />
        <h3 style={{ marginRight: "50px" }}>INTERIM SITE</h3>
      </div>
      <div
        style={{
          width: "100vw",
          height: "85%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{padding: '50px'}}>
          <h2>Unsere neue Website ist auf dem Weg!</h2>
          <h4>
            Bald zeigen wir hier spannende Projekte und Designs by
            Harre Interiors.
            </h4>
            <h4>
            <a href="mailto:hi@harre-interiors.com"> hi@harre-interiors.com </a>
          </h4>
        </div>
        <div style={{ height: "10%" }}></div>
      </div>
    </div>
  );
};

export default Background;
